import { render, staticRenderFns } from "./JustificationView.vue?vue&type=template&id=1dae0c69&scoped=true"
import script from "./JustificationView.vue?vue&type=script&lang=ts"
export * from "./JustificationView.vue?vue&type=script&lang=ts"
import style0 from "./JustificationView.vue?vue&type=style&index=0&id=1dae0c69&prod&scoped=true&lang=scss"
import style1 from "./JustificationView.vue?vue&type=style&index=1&id=1dae0c69&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dae0c69",
  null
  
)

export default component.exports